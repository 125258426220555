import React, { useState } from "react";
import { FaCopy, FaCheck } from "react-icons/fa";

const CopyToClipboardBox = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);

    textArea.select();
    document.execCommand("copy");

    document.body.removeChild(textArea);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="copy-box">
      <p style={{ fontSize: '1rem' }}>{textToCopy}</p>
      <button onClick={handleCopyClick}>
        {isCopied ? <FaCheck style={{ color: "white" }} /> : <FaCopy style={{ color: "white" }} />}
      </button>
    </div>
  );
};

export default CopyToClipboardBox;
