import React, { Component } from "react";
import "../App.css";
import Fade from "react-reveal";
import font from '../font';
import CopyToClipboardBox from '../Components/Copybox'

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBuyBoxOpen: false,
    };
  }

  toggleBuyBox = () => {
    this.setState((prevState) => ({
      isBuyBoxOpen: !prevState.isBuyBoxOpen,
    }));
  };

  render() {
    if (!this.props.data) return null;

    const description = this.props.data.description;

    return (
      <header id="home">
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav-link">
            <li className="current">
              <a className="smoothscroll" href="#home">
              <div className="nav-link">
                Home
                </div>
              </a>
            </li>
            
            <li className="wosmo-item" style={{ marginTop: '10px', marginBottom: '20px', color: 'white' }}>
    <style>
        {`
            @media (min-width: 768px) {
                .nav-link {
                    margin-left: 5px; /* Adjust the value as needed */
                }
            }
        `}
    </style>
    <div className="nav-link">
        BUY
    </div>
              {/* Collapsed box for "BUY" */}
              <div id="wosmoBox" className={`collapsed-box ${this.state.isBuyBoxOpen ? "open" : ""}`}>
                <a href="https://app.osmosis.zone/?to=WOSMO&from=OSMO" target="_blank">
                  <h1>Osmosis</h1>
                </a>
                <a href="https://app.astroport.fi/swap?from=inj&to=ibc/DD648F5D3CDA56D0D8D8820CF703D246B9FC4007725D8B38D23A21FF1A1477E3" target="_blank">
                  <h1>Astroport</h1>
                </a>
              </div>
            </li>
            
            <li>
            <style>
        {`
            @media (min-width: 768px) {
                .nav-link {
                    margin-left: 10px; /* Adjust the value as needed */
                }
            }
        `}
    </style>
    
              <a className="nav-link" href="https://commonwealth.im/wosmo-labs/overview" target="_blank">
              <div className="nav-link">
                COMMONWEALTH
                </div>
              </a>
            </li>

            <li>
              <a className="nav-link" href="https://twitter.com/WosmoInu" target="_blank">
              <div className="nav-link">
                TWITTER
              </div>
              </a>
            </li>

            <li>
              <a className="" href="https://wosmo.dev/telegram" target="_blank">
              <div className="nav-link">
                TELEGRAM
                </div>
              </a>
            </li>

            <li>
              <a className="" href="https://daodao.zone/dao/osmo1elw4psm8sddcvu8fsdx3hs0zkctcdzwu8qspuxzthepc6ev55c8qvp08u6/home" target="_blank">
              <div className="nav-link">
                DAO
                </div>
              </a>
            </li>

            <li>
              <a className="" href="https://drive.google.com/drive/folders/13ipU2oEBc95COjbLTHiCZLiopbfeovz_" target="_blank">
              <div className="nav-link">
                PRESS KIT
                </div>
              </a>
            </li>

          </ul>
        </nav>

        <div className="row banner" style={{ marginTop: '40px' }}>
          <div className="banner-text">
            <Fade bottom>
            <h1 style={{ fontFamily: 'PressStart2P', src: `url(${font})`, fontSize: '3rem', marginTop: '30px', marginBottom: '40px', letterSpacing: '2px'  }}>$WOSMO</h1>
              <img src="/images/WOSMO.png" alt="Image Description" className="centered-image rounded-image responsive-image"/>
              <h1 style={{ fontFamily: 'PressStart2P', src: `url(${font})`, fontSize: '2rem', fontWeight: 'bold', marginTop: '15px', marginBottom: '8px', letterSpacing: '2px' }}>THE FIRST NATIVE MEMECOIN ON OSMOSIS.</h1>

              <h1 style={{ fontFamily: 'PressStart2P', src: `url(${font})`, fontSize: '16px', marginTop: '30px' }}><span style={{ fontSize: '1.2em', position: 'relative', marginRight: '3px', top: '-2.5px' }}>🧪</span>A mad scientist with a touch of anarchy</h1>

            </Fade>
            <h4 style={{ fontSize: '1.7rem', color: 'white' }}>📦Max Supply $WOSMO : 1,000,000,000</h4>
            <h4 style={{ fontSize: '1.2rem', color: 'white', marginTop: '0px',marginBottom: '0px' }}>40% Airdrop:</h4>

            <h4 style={{ fontSize: '2rem', color: 'white' }}>$OSMO stakers</h4>
            <h4 style={{ fontSize: '1rem', color: 'white',marginTop: '-20px', marginBottom: '-5px' }}>(1:1 drop ratio to over 220k wallets)</h4>

            <h4 style={{ fontSize: '2rem', color: 'white', marginTop: '0px' }}>$ION holders</h4>
            <h4 style={{ fontSize: '1rem', color: 'white', marginTop: '-20px' }}>(1:50 drop ratio to over 5k wallets)</h4>

            <h4 style={{ fontSize: '1.7rem', color: 'white', marginTop: '20px' }}>🔮0 Taxes, 5% Growth, 0 Presale, 1 BS</h4>
            <h4 style={{ fontSize: '24px', color: 'white', marginTop: '20px' }}>Contract</h4>
{/* Contract Box */}
<CopyToClipboardBox textToCopy="factory/osmo1pfyxruwvtwk00y8z06dh2lqjdj82ldvy74wzm3/WOSMO"/>


            <Fade bottom duration={1200}>
              <h3 style={{color: 'gray', marginTop: '40px',fontSize: '1rem' }}>{description}.</h3>
            </Fade>
            <hr />
            
          </div>
        </div>

        
      </header>
    );
  }
}

export default Header;
